<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="ma-2" @click="addDefaultPaperworkSet()">Default Paper Set</v-btn>
      <v-btn color="primary" dark class="ma-2" @click="openPaperworkSetDialog()">Add Paper Set</v-btn>
      <v-btn color="primary" dark class="ma-2" @click="openPaperworkDialog()">New Paperwork</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="paperworks"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      :items-per-page="items_per_page"
      v-model="selectedRows"
      loading-text="Loading... Please wait"
      hide-default-footer
      show-select
    >
      <template v-slot:item.check_date="{ item }">
        {{ formatYesNo(item.check_date) }}
      </template>
      <template v-slot:item.completed="{ item }">
        {{ formatYesNo(item.completed) }}
      </template>
      <template v-slot:item.date_due="{ item }">
        {{ formatDate(item.date_due) }}
      </template>
      <template v-slot:item.date_completed="{ item }">
        {{ formatDate(item.date_completed) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-btn color="primary mt-5" v-if="selectedRows.length" dark @click="deleteSelected()">Delete</v-btn>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />

    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showdeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
    <v-dialog max-width="600" width="auto" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showPaperworkManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Paperwork Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showPaperworkManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupPaperworkDetailForm
            :subgroup_id="subgroup.id"
            :paperwork_id="paperwork_id"
            :isvisible="showPaperworkManageDialog"
            @closed="onPaperworkMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <v-dialog max-width="600" width="auto" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showPaperworkSetDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Paper Sets</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showPaperworkSetDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <SubgroupPaperworkSetForm
            :subgroup_id="subgroup.id"
            :isvisible="showPaperworkSetDialog"
            @closed="onPaperworkSetDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>
<script>
import { dateMixin } from '@/mixins/dateMixin';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import SubgroupPaperworkDetailForm from '@/views/SubgroupPaperworkDetailForm';
import SubgroupPaperworkSetForm from '@/views/SubgroupPaperworkSetForm';
import Paginate from '@/views/Paginate';

export default {
  name: 'SubroupPaperworkList',
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('paperwork/list', {
        subgroup_id: to.params.id,
        page: currentPage,
        per_page: 100,
        sort_by: 'id',
        sort_desc: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  mixins: [dateMixin],
  components: {
    SubgroupPaperworkSetForm,
    SubgroupPaperworkDetailForm,
    ConfirmDialog,
    Paginate
  },
  computed: {
    ...mapGetters('paperwork', ['loading', 'error', 'paperworks', 'meta', 'links']),
    ...mapGetters('subgroup', ['subgroup'])
  },
  methods: {
    load() {
      if (this.subgroup.id) {
        store.dispatch('paperwork/list', {
          page: this.options.page,
          per_page: this.items_per_page,
          sort_by: this.options.sortBy[0] ?? 'title',
          sort_desc: this.options.sortDesc[0] ?? false,
          subgroup_id: this.subgroup.id
        });
      }
    },
    async addDefaultPaperworkSet() {
      await store.dispatch('paperwork/createPaperworkFromPaperworkSet', {
        paperwork_group_id: this.subgroup.default_paperwork_group_id,
        subgroup_id: this.subgroup.id
      });
      this.load();
    },
    openPaperworkSetDialog() {
      this.showPaperworkSetDialog = true;
    },
    openPaperworkDialog() {
      this.paperwork_id = 'new';
      this.showPaperworkManageDialog = true;
    },
    editItem(item) {
      this.paperwork_id = item.id;
      this.showPaperworkManageDialog = true;
    },
    deleteItem(item) {
      this.itemsToDelete.splice(0, this.itemsToDelete.length);
      this.itemsToDelete[0] = item.id;
      this.showdeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showdeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    formatYesNo(value) {
      return value ? 'Yes' : 'No';
    },
    onPaperworkMangageDialogClosed() {
      this.showPaperworkManageDialog = false;
      this.load();
    },
    onPaperworkSetDialogClosed() {
      this.showPaperworkSetDialog = false;
      this.load();
    },
    async processDelete() {
      for (const id of this.itemsToDelete) {
        await store.dispatch('paperwork/delete', id);
        this.selectedRows = this.selectedRows.filter(x => {
          return x.id != id;
        });
      }
      this.load();
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('paperwork/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('paperwork/paginate', this.links.prev);
      }
    },
    deleteSelected() {
      this.itemsToDelete.splice(0, this.itemsToDelete.length);
      this.itemsToDelete = this.selectedRows.map(a => a.id);
      this.showdeleteConfirmDialog = true;
    }
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          this.load();
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    showdeleteConfirmDialog: false,
    showPaperworkManageDialog: false,
    showPaperworkSetDialog: false,
    paperwork_id: null,
    items_per_page: 100,
    selectedRows: [],
    itemsToDelete: [],
    options: {},
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Date Due', value: 'date_due' },
      { text: 'Check Date', value: 'check_date' },
      { text: 'Completed', value: 'completed' },
      { text: 'Date Completed', value: 'date_completed' },
      { text: 'Type', value: 'type' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
