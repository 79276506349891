import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Paperwork","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Paperwork","error-messages":errors},model:{value:(_vm.paperworkLocal.title),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "title", $$v)},expression:"paperworkLocal.title"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Type","clearable":""},model:{value:(_vm.paperworkLocal.paperwork_types_id),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "paperwork_types_id", $$v)},expression:"paperworkLocal.paperwork_types_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date Due","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Date Due","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.paperworkLocal.date_due),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "date_due", $$v)},expression:"paperworkLocal.date_due"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date Completed","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Date Completed","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.paperworkLocal.date_completed),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "date_completed", $$v)},expression:"paperworkLocal.date_completed"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Check Date"}},[_c(VSwitch,{attrs:{"label":"Check Date"},model:{value:(_vm.paperworkLocal.check_date),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "check_date", $$v)},expression:"paperworkLocal.check_date"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Completed"}},[_c(VSwitch,{attrs:{"label":"Completed"},model:{value:(_vm.paperworkLocal.completed),callback:function ($$v) {_vm.$set(_vm.paperworkLocal, "completed", $$v)},expression:"paperworkLocal.completed"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")]),_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.paperwork_id == 'new',"outlined":"","color":"primary"},on:{"click":_vm.deleteAction}},[_vm._v("Delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }