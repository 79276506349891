<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Paperset" rules="required">
              <v-radio-group v-model="paperworkGroupsRadioGroupId">
                <v-radio
                  v-for="paperworkGroup in paperworkGroups"
                  :key="paperworkGroup.id"
                  :label="paperworkGroup.title"
                  :value="paperworkGroup.id"
                  :disabled="loading"
                ></v-radio>
              </v-radio-group>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="okAction" :disabled="loading || invalid" class="primary">Ok</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';

extend('required', required);

export default {
  name: 'SubgroupPaperworkSetForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ['subgroup_id'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('paperworkgroup', ['loading', 'paperworkGroups', 'error'])
  },
  methods: {
    /**
     * Load the paperwork
     */
    loadAction: function () {
      store.dispatch('paperworkgroup/list', 1);
    },
    /**
     * Save method for the form
     */
    async okAction() {
      await store.dispatch('paperwork/createPaperworkFromPaperworkSet', {
        paperwork_group_id: this.paperworkGroupsRadioGroupId,
        subgroup_id: this.subgroup_id
      });
      this.$emit('closed');
    }
  },
  mounted: async function () {
    this.loadAction();
  },
  data: () => ({
    paperworkGroupsRadioGroupId: null
  })
};
</script>
