import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Paperset","rules":"required"}},[_c(VRadioGroup,{model:{value:(_vm.paperworkGroupsRadioGroupId),callback:function ($$v) {_vm.paperworkGroupsRadioGroupId=$$v},expression:"paperworkGroupsRadioGroupId"}},_vm._l((_vm.paperworkGroups),function(paperworkGroup){return _c(VRadio,{key:paperworkGroup.id,attrs:{"label":paperworkGroup.title,"value":paperworkGroup.id,"disabled":_vm.loading}})}),1)],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid},on:{"click":_vm.okAction}},[_vm._v("Ok")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }