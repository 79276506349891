<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Paperwork" rules="required|min:2|max:255">
              <v-text-field
                v-model="paperworkLocal.title"
                :disabled="loading"
                label="Paperwork"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="paperworkLocal.paperwork_types_id"
                :disabled="loading"
                :items="lists.types"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Type"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Date Due" rules="date">
              <DateInput
                v-model="paperworkLocal.date_due"
                label="Date Due"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Date Completed" rules="date">
              <DateInput
                v-model="paperworkLocal.date_completed"
                label="Date Completed"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Check Date">
              <v-switch v-model="paperworkLocal.check_date" label="Check Date"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Completed">
              <v-switch v-model="paperworkLocal.completed" label="Completed"></v-switch>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
        <v-btn @click="deleteAction" :disabled="loading || paperwork_id == 'new'" outlined color="primary" class="ma-2"
          >Delete</v-btn
        >
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import { date } from '@/utils/helpers';
import DateInput from '@/views/DateInput';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'SubgroupPaperworkDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput
  },
  props: ['subgroup_id', 'paperwork_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('paperwork', ['loading', 'paperwork', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        types: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    paperworkLocal: function () {
      return loadDash.cloneDeep(this.paperwork);
    }
  },
  methods: {
    /**
     * Load the paperwork
     */
    loadAction: function () {
      store.dispatch('paperwork/get', this.paperwork_id);
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result && this.subgroup_id > 0) {
        this.paperworkLocal.subgroup_id = this.subgroup_id;
        store.dispatch('paperwork/save', {
          id: this.paperworkLocal.id,
          paperwork: this.paperworkLocal
        });
      }
      this.$emit('closed');
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      store.dispatch('paperwork/delete', this.paperworkLocal.id);
      this.$emit('closed');
    }
  },
  mounted: async function () {
    this.loadAction();
  },
  watch: {
    isvisible: function () {
      if (this.isvisible) {
        this.loadAction();
      }
    },
    paperworkLocal: function () {
      this.$refs.objects.reset();
    }
  }
};
</script>
